export default {
  flatData: (state) => {
    return state.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data)
    }, [])
  },

  detailTitles: (state) =>{
  	return !!state.detail && state.detail.title ? [state.detail.title] : []
  },

  info: (state)=>{
  	let ar = [];
  	if(!!state.detail){

      if(state.detail.customer){
        ar.push({ label: gettext('Cliente'), value: state.detail.customer.title  })
      }

      if(!!Object.keys(state.detail.agency).length){
        ar.push({ label: gettext('Committente'), value: state.detail.agency.title  })
      }

      if(state.detail.publication_date){
        ar.push({ label: gettext('Anno'), value: state.detail.publication_date  })
      }

  		if(Array.isArray(state.detail.services) && state.detail.services.length > 0){
  			ar.push({ label: gettext('Servizi'), value: state.detail.services.join('<br />')  })
  		}

      if(Array.isArray(state.detail.technologies) && state.detail.technologies.length > 0){
        ar.push({ label: gettext('Tecnologie'), value: state.detail.technologies.join('<br />') })
      }
  	}

  	return ar
  }
}
