/* Mutations, actions and getters */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    privacy: null,
    cookie: null,
    personalData: null
  },
  actions,
  mutations,
  getters
}
