import axios from 'axios'
import {mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState('i18n', ['catalog', 'formats', 'plural'])
  },
  methods: {
    /* pluralidx */
    pluralidx(n) {
      if (this.plural) {
        let v = n !== 1;
        if (typeof v === 'boolean') {
          return v ? 1 : 0;
        } else {
          return v;
        }
      } else {
        return n === 1 ? 0 : 1;
      }
    },

    /* gettext library */
    gettext(msgid) {
      let value = this.catalog[msgid];
      if (typeof value === 'undefined') {
        return msgid;
      } else {
        return (typeof value === 'string') ? value : value[0];
      }
    },

    ngettext(singular, plural, count) {
      let value = this.catalog[singular];
      if (typeof value === 'undefined') {
        return (count === 1) ? singular : plural;
      } else {
        return value[pluralidx(count)];
      }
    },

    gettext_noop(msgid) {
      return msgid;
    },

    pgettext(context, msgid) {
      let value = this.gettext(context + String.fromCharCode(0x04) + msgid);
      if (value.indexOf(String.fromCharCode(0x04)) !== -1) {
        value = msgid;
      }
      return value;
    },

    npgettext(context, singular, plural, count) {
      let value = this.ngettext(context + String.fromCharCode(0x04) + singular, context + String.fromCharCode(0x04) + plural, count);
      if (value.indexOf(String.fromCharCode(0x04)) !== -1) {
        value = this.ngettext(singular, plural, count);
      }
      return value;
    },

    interpolate(fmt, obj, named) {
      if (named) {
        return fmt.replace(/%\(\w+\)s/g, function (match) {
          return String(obj[match.slice(2, -2)])
        });
      } else {
        return fmt.replace(/%s/g, function (match) {
          return String(obj.shift())
        });
      }
    },

    /* formatting library */
    get_format(format_type) {
      let value = this.formats[format_type];
      if (typeof value === 'undefined') {
        return format_type;
      } else {
        return value;
      }
    }
  }
}
