import axios from 'axios'
import * as types from './mutation_types'

export default {
  async loadAboutData ({ commit, rootState }, { meta, name, useFixture }) {
    // Get all 'about-related' data
    switch (name) {
      case 'about.what':
        const what = useFixture ?
          (await import('@fixtures/about_data')).WHAT :
          (await axios.get(rootState.api['about-what'])).data
        commit(types.SET_WHAT_CONTENT, what)
        break
      case 'about.who':
        const who = useFixture ?
          (await import('@fixtures/about_data')).WHO :
          (await axios.get(rootState.api['about-who'])).data
        commit(types.SET_WHO_CONTENT, who)
        break
      case 'about.where':
        commit(types.SET_WHERE_CONTENT, meta)
        break
    }
  }
}
