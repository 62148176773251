<template>
  <div :class="['Loader', themeClass]" :style="{backgroundColor}">
    <div class="wrapper">
      <vue-typed-js
        class="title"
        :strings="[` ${pgettext('loader terminal', 'make future --now')}`]"
        :type-speed="25"
        :start-delay="1500"
        cursor-char="_"
        :contentType="null"
      >
        <span class="typing"></span>
      </vue-typed-js>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'loader',
    props: {
      themeClass: { type: String, default: 'fullscreen' },
      backgroundColor: { type: String, default: 'var(--color-blue-accent)' },
      mainColor: { type: String, default: 'var(--color-white)' }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
  }

  .wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }

  .title {
    display: block;
    text-align: center;
    color: $white;
    font-size: 25px;
    margin: 0;
    padding:0 5px;

    @media (min-width: $bootstrap-md) {
      font-size: 32px;
    }
    @media (min-width: $bootstrap-lg) {
      font-size: 40px;
    }

    &:before {
      content: 'fh@frankhood';
      display: inline-block;
      padding-right: 1em;
      font-weight: $bold;
    }
  }

  .squares {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    transform-origin: bottom left;
    animation: bigSqrShrink 1s linear infinite;

    @keyframes bigSqrShrink {
      0% {
        transform: scale(1)
      }
      90% {
        transform: scale(1)
      }
      100% {
        transform: scale(0.5)
      }
    }
    @keyframes drop2 {
      0% {
        transform: translateY(-50px)
      }
      25% {
        transform: translate(0)
      }
      100% {
        transform: translate(0)
      }
    }
    @keyframes drop3 {
      0% {
        transform: translateY(-50px)
      }
      50% {
        transform: translate(0)
      }
      100% {
        transform: translate(0)
      }
    }

    @keyframes drop4 {
      0% {
        transform: translateY(-50px)
      }
      75% {
        transform: translate(0)
      }
      100% {
        transform: translate(0)
      }
    }

    .square {
      position: absolute;
      width: 50%;
      height: 50%;

      &.first {
        left: 0;
        top: 50%;
      }

      &.second {
        left: 50%;
        top: 50%;
        animation: drop2 1s linear infinite;
      }

      &.third {
        left: 0;
        top: 0;
        animation: drop3 1s linear infinite;
      }

      &.fourth {
        left: 50%;
        top: 0;
        animation: drop4 1s linear infinite;
      }
    }
  }
</style>
