(<template>
  <header :class="['PageHeader', themeClass]">
    <div class="headerWrapper" ref="headerWrapper">
      <a @click.prevent="navigate('home')" class="brand">
        <svg
          width="100%"
          viewBox="0 0 100 12"
        >
          <use href="#icon-brand-fh" fill="currentColor" />
        </svg>
        <i class="ir">Frankhood</i>
      </a>

      <nav role="navigation" class="headerNav" ref="navWrapper">
        <button
          :class="['hamburger', 'hamburger--squeeze', {'is-active': menuOpen}]"
          type="button"
          @click="toggleMenu"
          ref="hamburger"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <div class="navBarEntries">
          <router-link
            :class="['link', 'navLink', entry.classes]"
            v-for="entry in mainMenu"
            :key="entry.name"
            :to="entry">
            {{ entry.label }}
          </router-link>
        </div>
      </nav>

      <aside
        class="sideMenu"
        v-offcanvas:main-menu="{
            lockScroll: false,
            position: 'right',
            openAnimation: 'slide-in',
            closeAnimation: 'slide-out',
            beforeEnter: notifyOpening
        }"
      >
        <div class="menuWrapper" ref="menuWrapper">
          <router-link
            class="link navLink"
            v-for="entry in mainMenu"
            :key="`hlink-${entry.name}`"
            :to="entry">
            {{ entry.label }}
          </router-link>
        </div>
      </aside>
    </div>
  </header>
</template>)

<script>
  import { mapGetters, mapState } from 'vuex'
  import { TweenLite } from 'gsap'
  import Vue from 'vue'
  import VueOffcanvas from '@plugins/vue_offcanvas'
  import store from '@store'

  Vue.use(VueOffcanvas, { store })

  export default {
    name: 'PageHeader',
    props: {
      themeClass: { type: String, default: '' }
    },
    computed: {
      ...mapState(['mainMenu']),
      ...mapState('vueOffcanvas', ['mainPanel']),
      ...mapGetters('vueOffcanvas', ['offcanvasOpeningState', 'getOffcanvas']),
      menuOpen () {
        return this.$store.getters['vueOffcanvas/offcanvasOpeningState']('main-menu')
      }
    },
    methods: {
      navigate (name) {
        this.$router.push({ name: name })
      },
      toggleMenu () {
        return new Promise((resolve, reject) => {
          let $hamburger = this.$refs.hamburger,
            $headerWrapper = this.$refs.headerWrapper,
            $navWrapper = this.$refs.navWrapper,
            $mainMenu = this.$refs.menuWrapper,
            rect = $hamburger.getBoundingClientRect()

          if (!this.menuOpen) {
            TweenLite.set($headerWrapper, {
              height: $headerWrapper.clientHeight
            })

            $navWrapper.removeChild($hamburger)
            $mainMenu.appendChild($hamburger)

            TweenLite.set($hamburger, {
              position: 'absolute',
              top: rect.y + 50,
              right: window.innerWidth - rect.right
            })

            this.$openOffcanvas('main-menu')
              .then(resolve)
          } else {
            $mainMenu.removeChild($hamburger)
            $navWrapper.appendChild($hamburger)

            TweenLite.set($hamburger, {
              position: 'static'
            })

            TweenLite.set($headerWrapper, {
              height: 'auto'
            })

            this.$closeOffcanvas('main-menu')
              .then(resolve)
          }
        })
      },
      notifyOpening () {
        this.$emit('menu-opening')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  @import '~$scss/links';

  $link-color: $dark-blue;

  .positionAbsolute {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    @media (min-width: $bootstrap-md){
      padding: 30px;
    }
  }

  .sideMenu {
    background-color: rgba($dark-blue, .9);
    height: 100vh;
    z-index: 1999;

    .menuWrapper {
      position: relative;
      padding: 180px 40px;
    }

    .navLink {
      display: block;
      margin-bottom: 12px;
      color: $white;
      font-size: 16px;

      @media (min-width: $bootstrap-sm) {
        font-size: 18px;
      }

      @media (min-width: $bootstrap-md) {
        font-size: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .brand {
    display: block;
    font-size: 0;
    text-decoration: none;
    cursor: pointer;
    width: 200px;
    height: 25px;
    @media (min-width: $bootstrap-md){
      width: 240px;
      height: 30px;
    }

    .ir {
      text-indent: -9999px;
    }

    img {
      width: 100%;
    }
  }

  .whiteTheme {
    --brand-color-primary: white;
  }

  .headerNav {
    position: relative;

    .navBarEntries {
      display: none;
      @media (min-width: $bootstrap-xl) {
        display: block;
      }
    }

    .navLink {
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;

      &:last-child {
        margin: 0;
        min-width: 140px;
        text-align: center;
      }
    }
  }

  .whiteTheme {
    .navLink {
      color: $white;
    }

    .link.button {
      border: 1px solid $white;
      background: transparent;
      color: $white;
      transition: all 0.5s ease-out;

      &:hover {
        border: 1px solid $dark-blue;
        background: $white;
        color: $dark-blue;
      }
    }
  }

  $hamburger-padding-x: 10px;
  $hamburger-padding-y: 10px;
  $hamburger-layer-width: 30px;
  $hamburger-layer-height: 3px;
  $hamburger-layer-spacing: 6px;
  $hamburger-layer-color: #ffffff;
  $hamburger-layer-border-radius: 2px;
  $hamburger-hover-opacity: 0.7;
  $hamburger-active-layer-color: $hamburger-layer-color;
  $hamburger-active-hover-opacity: $hamburger-hover-opacity;
  @import '~hamburgers/_sass/hamburgers/hamburgers';

  .hamburger {
    font-size: 0;
    background-color: fade-out(#100B2F, 0.1);
    z-index: 2000;

    @media (min-width: $bootstrap-xl) {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &:hover, &.is-active:hover {
      opacity: 1;
    }
  }
</style>
