/* Import dependencies */
//import { mock, axios } from '@fixtures/axios_mock_adapter'
import axios from 'axios'
import { CATALOG } from '../../fixtures/i18n'
import * as types from '../mutation_types';

export default {
  getCatalog({commit, rootState}, useFixture) {
    return new Promise((resolve, reject) => {

      // if(useFixture){
      //   mock.onGet(rootState.django_vuex.api.i18n).reply(200, CATALOG);
      // }

      axios.get(rootState.api.i18n)
        .then(response => {
          commit(types.SET_I18N_CATALOG, response.data);
          //mock.restore()
          resolve()
        })
        .catch(error => {
          console.warn('i18n catalog API unavailable');
          console.warn(error);
          //DECOMMENTARE DOPO AGGIUNTA API
          reject(error)
        })
    })
  },
}
