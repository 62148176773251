import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Offcanvas from './offcanvas'
import offcanvasStoreModule from './store'
import { toCamelCase } from './utils'
import * as animations from './animations'

const GsapPlugins = [ScrollToPlugin]

const VueOffcanvas = {
  install (Vue, options) {

    if (!options || !options.store) {
      throw new Error('Vue Offcanvas: please, provide a Vuex store in the options object')
    }
    options.store.registerModule('vueOffcanvas', offcanvasStoreModule)

    /* Vue instance methods */

    // Save and recover scroll position
    Vue.prototype.$lockScroll = function () {
      let scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName('html')[0].scrollTop
      this.$store.dispatch('vueOffcanvas/saveScroll', scrollPos)
        .then(savedScroll => {
          TweenLite.set(options.store.state.vueOffcanvas.mainPanel, {
            position: 'fixed',
            left: 0,
            top: -savedScroll
          })
        })
    }

    Vue.prototype.$recoverScroll = function () {
      TweenLite.set(options.store.state.vueOffcanvas.mainPanel, {
        position: 'static'
      })
      TweenLite.set(window, {scrollTo: this.$store.state.vueOffcanvas.lastScrollPosition})
      this.$store.dispatch('vueOffcanvas/saveScroll', null)
    }

    // Open offcanvas
    Vue.prototype.$openOffcanvas = function (element) {
      return new Promise((resolve, reject) => {
        let $oc = this.$store.getters['vueOffcanvas/getOffcanvas'](element),
          animationFn = toCamelCase($oc.openAnimation)

        if ($oc.lockScroll && !this.$store.state.vueOffcanvas.lastScrollPosition) {
          this.$lockScroll()
        }

        new Promise((resolve, reject) => {
          try {
            $oc.beforeEnter.call(this)
            resolve()
          } catch (e) {
            reject(e)
          }
        })
          .then(() => new Promise((resolve, reject) => {
            let $mainPanel = this.$store.state.vueOffcanvas.mainPanel
            if ($oc.lockScroll) {
              if (!document.body.contains($oc.el)) {
                document.body.appendChild($oc.el)
              }
            } else {
              if (!$mainPanel.contains($oc.el)) {
                $mainPanel.appendChild($oc.el)
              }
            }
            resolve()
          }))
          .then(() => animations[animationFn]($oc))
          .then(() => {
            return new Promise((resolve, reject) => {
              try {
                $oc.beforeStateChange.call(this)
                resolve()
              } catch (e) {
                reject(e)
              }
            })
          })
          .then(() => this.$store.dispatch('vueOffcanvas/setOpeningState', {
            offcanvas: $oc,
            openingState: true
          }))
          .then(() => {
            return new Promise((resolve, reject) => {
              try {
                $oc.afterEnter.call(this)
                resolve()
              } catch (e) {
                reject(e)
              }
            })
          })
          .then(resolve)
          .catch(resolve)
      })
    }

    // Close offcanvas
    Vue.prototype.$closeOffcanvas = function (element) {
      return new Promise((resolve, reject) => {
        let $oc = this.$store.getters['vueOffcanvas/getOffcanvas'](element),
          animationFn = toCamelCase($oc.closeAnimation)

        // Check if other scroll-locking panels are open
        // TODO simplify finding other open scroll-locking panels
        let ocDict = this.$store.state.vueOffcanvas.offCanvasElements,
          ocList = Object.keys(ocDict),
          otherLockingOpen = ocList.reduce((acc, key) => {
            if (ocDict.hasOwnProperty(key)) {
              let $ocObj = ocDict[key]
              if ($ocObj.isOpen && $ocObj.lockScroll && $ocObj.name !== $oc.name) {
                return acc || true
              } else {
                return acc || false
              }
            } else {
              return acc || false
            }
          }, false)

        new Promise((resolveInner, rejectInner) => {
          try {
            $oc.beforeLeave.call(this)
            resolveInner()
          } catch (e) {
            rejectInner(e)
          }
        })
          .then(() => animations[animationFn]($oc))
          .then(() => new Promise((resolveInner) => {
            if ($oc.lockScroll && !$oc.isAnimating && !otherLockingOpen) {
              this.$recoverScroll()
              resolveInner()
            } else {
              resolveInner()
            }
          }))
          .then(() => {
            return new Promise((resolve, reject) => {
              try {
                $oc.beforeStateChange.call(this)
                resolve()
              } catch (e) {
                reject(e)
              }
            })
          })
          .then(() => $oc.pushAway())
          .then(() => this.$store.dispatch('vueOffcanvas/setOpeningState', {
            offcanvas: $oc,
            openingState: false
          }))
          .then(() => {
            return new Promise((resolve, reject) => {
              try {
                $oc.afterLeave.call(this)
                resolve()
              } catch (e) {
                reject(e)
              }
            })
          })
          .then(() => {
            resolve()
          })
          .catch(resolve)
      })
    }

    // Toggle offcanvas
    Vue.prototype.$toggleOffcanvas = function (element) {
      return new Promise((resolve, reject) => {
        let $oc = this.$store.getters['vueOffcanvas/getOffcanvas'](element)

        if ($oc.isOpen) {
          this.$closeOffcanvas($oc)
            .then(resolve)
        } else {
          this.$openOffcanvas($oc)
            .then(resolve)
        }
      })
    }

    /* Directives */

    // Configure the main app panel
    Vue.directive('main-panel', {
      bind (el, binding, vnode, oldVnode) {
        if (!!vnode.context.$store.state.vueOffcanvas.mainPanel) {
          throw new Error('More than a main panel has been defined')
        } else {
          vnode.context.$store.dispatch('vueOffcanvas/setMainPanel', el)
        }
      }
    })

    // Configure secondary panels
    // Vue.directive('panel', {
    //   bind(el, binding, vnode) {
    //     let panels = vnode.context.$panels,
    //       name = binding.arg;
    //
    //     if(panels.hasOwnProperty(name)) {
    //       throw new Error(`A panel named '${name}' already exists`)
    //     } else {
    //       panels[name] = el;
    //     }
    //   }
    // });

    // Configure the offcanvas elements
    Vue.directive('offcanvas', {
      bind (el, binding, vnode) {
        let name = binding.arg,
          options = binding.value
        vnode.context.$store.dispatch('vueOffcanvas/addOffcanvas', new Offcanvas(el, name, options))
          .then(() => el.parentElement.removeChild(el))
      }
    })
  }
}

export default VueOffcanvas
