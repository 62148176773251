/* Import dependencies */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* Mutations, actions and getters */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

/* Modules */
import home from './home'
import news from './news'
import i18n from './i18n'
import form from './form'
import experiments from './experiments'
import works from './works'
import about from './about'
import policies from './policies'
import careers from './careers'
import brands from './brands'
import services from './services'
import approach from './approach'
import ai from './ai'

export default new Vuex.Store({
  state: {
    mainMenu: [],
    appReady: false,
    navigating: false,
    metaData: {},
    api: null,
    currentViewport: '',
    activateLanguageSelector: true
  },
  actions,
  mutations,
  getters,
  modules: {
    home,
    news,
    i18n,
    form,
    experiments,
    works,
    about,
    policies,
    careers,
    brands,
    services,
    approach,
    ai
  }
})
