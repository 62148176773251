/* Mutations, actions and getters */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    rules: {},
    form: {
      first_name: '',
      last_name: '',
      email: '',
      contact_reason: 'spontaneous',
      message: '',
      accepts_privacy: ''
    },
    status: ''
  },
  actions,
  mutations,
  getters
}
