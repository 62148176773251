import { TweenLite, Power3 } from 'gsap'
import { parsePosition, outOfCanvasStyles } from './utils'

export const fadeIn = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 0,
            ...parsePosition(position)
          }, {
            ease,
            autoAlpha: 1,
            ...parsePosition(position),
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'relative'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}

export const fadeOut = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 1,
            ...parsePosition(position)
          }, {
            ease,
            autoAlpha: 0,
            ...parsePosition(position),
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'fixed'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}

export const slideIn = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          let finalPosition = parsePosition(position),
            initialPosition = outOfCanvasStyles(finalPosition)

          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 1,
            ...initialPosition
          }, {
            ease,
            autoAlpha: 1,
            ...finalPosition,
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'relative'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}

export const slideOut = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          let initialPosition = parsePosition(position),
            finalPosition = outOfCanvasStyles(initialPosition)

          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 1,
            ...initialPosition
          }, {
            ease,
            autoAlpha: 1,
            ...finalPosition,
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'fixed'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}

export const slideFadeIn = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          let finalPosition = parsePosition(position),
            initialPosition = outOfCanvasStyles(finalPosition)

          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 0,
            ...initialPosition
          }, {
            ease,
            autoAlpha: 1,
            ...finalPosition,
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'relative'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}

export const slideFadeOut = function ($offcanvas) {
  let $el = $offcanvas.el,
    lockScroll = $offcanvas.lockScroll,
    position = $offcanvas.position,
    duration = $offcanvas.duration / 1000,
    ease = $offcanvas.ease ?
      Power3.easeIn : null

  return new Promise((resolve, reject) => {
    if (!$offcanvas.isAnimating) {
      $offcanvas.setAnimationState(true)
        .then(() => {
          let initialPosition = parsePosition(position),
            finalPosition = outOfCanvasStyles(initialPosition)

          TweenLite.fromTo($el, duration, {
            ease,
            autoAlpha: 1,
            ...initialPosition
          }, {
            ease,
            autoAlpha: 0,
            ...finalPosition,
            onComplete: () => {
              if (lockScroll) {
                TweenLite.set($el, {position: 'fixed'})
              }
              $offcanvas.setAnimationState(false)
                .then(resolve)
            }
          })
        })
    } else {
      reject('Element already animating')
    }
  })
}
