import axios from 'axios'
import * as types from './mutation_types'

export default {
  //{commit,dispatch,getters,rootGetters,rootState,state}
  initializeApplication ({commit}, context) {
    return new Promise((resolve) => {
      commit(types.SET_APP_READY)
      resolve()
    })
  },
  setNavigationStatus ({commit}, status) {
    return new Promise((resolve) => {
      commit(types.SET_NAVIGATION_STATUS, status)
      resolve()
    })
  },
  retrieveMainMenu({ commit, state }, url){
    return new Promise((resolve, reject) => {
      if(!!url){
        axios.get(url).then(response => {
          commit(types.SET_MAIN_MENU, response.data)
        }).finally(() => {
          resolve()
        })
      }else{
        console.warn('[MENU API] ENDPOINT NOT SET, FAKE RESOLVE');
        resolve()
      }
    })
  },
  mapApi ({commit}, apiObject) {
    return new Promise((resolve) => {
      commit(types.MAP_API, apiObject)
      resolve()
    })
  },
  setFullscreenMessage ({commit}, message) {
    return new Promise((resolve) => {
      commit(types.SET_FULLSCREEN_MESSAGE, message)
      resolve()
    })
  },
  setCurrentPolicy ({commit, state}, slug) {
    return new Promise((resolve) => {
      commit(types.SET_CURRENT_POLICY, slug)
      resolve()
    })
  },
  retrievePageMeta ({commit, state}, {cType, id}) {
    return new Promise((resolve, reject) => {
      if(!!state.api['seo']){
        axios.get(state.api['seo'], {
          params: {
            cType,
            idAttribute: id
          }
        }).then(response => {
          commit(types.SET_SEO_META, response.data.metaInfo)
        }).finally(() => {
          resolve()
        })
      }else{
        console.warn('[SEO API] ENDPOINT NOT SET, FAKE RESOLVE');
        resolve()
      }
    })
  },

  setCurrentViewport({ commit, state }, w){
    let viewport;
    return new Promise((resolve)=>{
      /*
      @bootstrap-sm: 576px;
      @bootstrap-md: 768px;
      @bootstrap-lg: 992px;
      @bootstrap-xl: 1200px;
      */
      if(w < 321){
        viewport = 'xs'
      } else if(w < 576){
        viewport = 'sm'
      } else if(w < 769){
        viewport = 'md'
      } else if(w < 993){
        viewport = 'lg'
      } else if(w < 1201){
        viewport = 'xl'
      }else {
        viewport = 'xxl'
      }
      //console.log('setCurrentViewport', w, viewport);
      commit(types.SET_CURRENT_VIEWPORT_WIDTH, viewport)
      resolve()
    });
  }
}
