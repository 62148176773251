/* Import dependencies */
//import { mock, axios } from '@fixtures/axios_mock_adapter'
import axios from 'axios'
import * as types from './mutation_types'

export default {
  initPagination ({commit, state, rootState}, {page_size}) { // Get first page
    return new Promise((resolve, reject) => {
      axios.get(rootState.api['news-list'], {params: {page_size}})
        .then(response => {
          let count, current_page, final_page,
            next, previous, page
          ({count, current_page, final_page, next, previous} = response.data)
          page = {
            page_number: current_page,
            data: response.data.results
          }

          commit(types.UPDATE_PAGINATION, {count, current_page, final_page, next, previous, page_size})
          commit(types.ADD_PAGE, page)

          resolve()
        })
        .catch(error => {
          console.warn('[NEWS API] error', error);
          console.warn(error);
          reject(error)
        })
    })
  },
  loadMore ({commit, state}) {
    return new Promise((resolve, reject) => {
      if (!!state.pagination.next) {
        axios.get(state.pagination.next)
          .then(response => {
            let count, current_page, final_page,
              next, previous, page
            ({count, current_page, final_page, next, previous} = response.data)
            page = {
              page_number: current_page,
              data: response.data.results
            }

            commit(types.UPDATE_PAGINATION, {count, current_page, final_page, next, previous})
            commit(types.ADD_PAGE, page)

            resolve()
          })
          .catch(reject)
      } else {
        reject(new Error('No more pages available'))
      }
    })
  },
  setDetail({ commit, state, rootState }, obj){
    return new Promise((resolve, reject) => {
      commit(types.SET_DETAIL, obj)
      resolve()
    })
  },
  getDetail ({commit, state, rootState}, slug) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.api['news-list'] + slug + '/')
        .then(response => {
          commit(types.SET_DETAIL, response.data)
          resolve()
        })
        .catch(reject)
    })
  }
}
