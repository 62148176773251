(<template>
  <div
    :class="['Frankhood', activeRoute]"
    v-main-panel
  >
    <div
      v-if="this.$route.meta.headerImageUrl || this.$route.meta.headerImageUrlMobile "
      :class="['headerBackground', aiBgClass ]"
      :style="styles"
    ></div>
    <page-header :themeClass="$route.meta.headerTheme"></page-header>
    <common-icons />
    <main>
      <router-view v-if="appReady || usePrerender"></router-view>
    </main>
    <page-footer></page-footer>
    <div
      :class="{'fullscreenLoader sitePreloader': !pageLoaderDisabled}"
      v-if="!appReady && !usePrerender"
    >
      <loader></loader>
    </div>
    <div
      :class="{'fullscreenLoader pageLoader': !pageLoaderDisabled && !usePrerender}"
      v-offcanvas:page-loader="{
      lockScroll: false,
      position: 'bottom',
      enterFrom: 'bottom',
      exitTo: 'top',
      duration: 500,
      openAnimation: 'slide-in',
      closeAnimation: 'fade-out',
      ease: true,
      beforeEnter:beforeEnter,
      afterEnter: hideBody,
      beforeLeave: showBody,
      afterLeave: afterLeave
    }"
    >&nbsp;
    </div>
  </div>
</template>
)
<script>
import { mapState } from 'vuex'
import PageHeader from '@components/page_header'
import PageFooter from '@components/page_footer'
import Loader from '@components/loader'
import CommonIcons from '@components/common_icons'

export default {
  name: 'Frankhood',
  components: {
    PageHeader,
    PageFooter,
    Loader,
    CommonIcons
  },
  metaInfo() {
    return this.metaData
  },
  data() {
    return {
      pageLoaderLeaved:true,
      addedCookiebotStylesForChangeConsent: false,
      pageLoaderDisabled: false,
      usePrerender:
        window.navigator.userAgent.indexOf(" Prerender ") !== -1 ||
        window.navigator.userAgent.indexOf("seo4ajax") !== -1,
    }
  },
  computed: {
    ...mapState(['metaData']),
    ...mapState(['appReady', 'mainMenu']),
    ...mapState('vueOffcanvas', ['mainPanel']),
    activeRoute() {
      let name = this.$route.name
      return name ? `route-${name}` : null
    },
    styles() {
      let stylesObj = {}


      if (this.$route.meta.headerImageUrl) {
        stylesObj = Object.assign(stylesObj, {
          backgroundImage: `url('${this.$route.meta.headerImageUrl}')`,
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        })
      }
      if (this.$route.meta.headerTheme === 'ai-background') {
        stylesObj = Object.assign(stylesObj, {
          backgroundPosition: this.$viewportWidth < 1200 ? 'right bottom' : 'right top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: this.$viewportWidth < 768 ? `url('${this.$route.meta.headerImageUrlMobile}')` : `url('${this.$route.meta.headerImageUrl}')`
        })
      }
      if (this.$route.meta.headerImageColor) {
        stylesObj = Object.assign(stylesObj, {
          backgroundColor: this.$route.meta.headerImageColor,
        })
      }

      return stylesObj
    },
    aiBgClass() {
      return this.$route.meta.headerTheme === 'ai-background' ? 'ai-header' : ''
    }
  },
  methods: {
    hideBody() {
      this.mainPanel.classList.add('covered')
    },
    showBody() {
      this.mainPanel.classList.remove('covered')
    },
    beforeEnter(){
      this.$root.pageLoaderLeaved=false;
    },
    afterLeave(){
      this.$root.pageLoaderLeaved=true;
    }
  },
  watch: {
    "$store.state.navigating": {
      handler(newVal) {

        let cookieBotDialog = document.getElementById("CybotCookiebotDialog");

        if (!this.usePrerender && cookieBotDialog) {
          if (newVal) {
            cookieBotDialog.style.opacity = "0";
          } else {
            setTimeout(() => {
              cookieBotDialog.style.opacity = "1";
            }, 0);
          }
        }
        if (!this.addedCookiebotStylesForChangeConsent) {
          var css = "#pageBody #CybotCookiebotDialog{opacity:1;}",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          head.appendChild(style);

          style.type = "text/css";
          if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }
          this.addedCookiebotStylesForChangeConsent = true
        }

      },
    },
    $route: {
      immediate: true,
      handler(to, from) {
        this.pageLoaderDisabled = from && !!from.name && (to.name === 'services' || to.name === 'services.detail')
      },
    }
  },
  mounted() {
    window.addEventListener('click', event => {
      const { target } = event
      // handle only links that do not reference external resources
      if (target && target.matches('a:not([href*=\'://\'])') && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        // don't handle same page links/anchors
        const url = new URL(target.href)
        const to = url.pathname
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          this.$router.push(to)
        }
      }
    })
  }
}
</script>
<style
  lang="scss"
  scoped
>
@import '~$scss/variables';

.Frankhood {
  position: relative;
  overflow: hidden;

  .headerBackground {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: $bootstrap-md) {
      height: 415px;
    }

    &.ai-header {
      height: 580px;

      @media (min-width: $bootstrap-md) {
        height: 555px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 632px;
      }

      @media (min-width: $bootstrap-xl) {
        height: 717px;
      }


    }
  }

  &.route-news .headerBackground {
    height: 600px;
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    background-color: $white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }

  &.covered:after {
    display: block;
  }
}

.fullscreenLoader {
  height: 100vh;
  width: 100%;
  background-color: $white;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pageLoader {
    box-shadow: 0 0 150px 200px rgba(#111111, 0.1);
  }

  &.sitePreloader {
    position: fixed;
    background-color: $white;
    z-index: 1999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.cookieTerminal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 75vw;
  height: auto;
  max-height: 100vh;
  z-index: 1800;

  @media (min-width: $bootstrap-md) {
    width: 50vw;
  }

  @media (min-width: $bootstrap-xl) {
    width: 30vw;
  }
}

.cookieConsentClassic {
  padding: 30px 0;
  display: block;
  text-align: center;
  background-color: $dark-blue;

  .copy {
    font-size: 12px;
    color: $white;
    margin: 0 0 15px;

    @media (min-width: 414px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .link,
  .link:visited {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;

    &.button {
      border: 1px solid #ffffff;
      background: transparent; //fadeout(@white, 25%);
      color: #ffffff;
      transition: all 0.5s ease-out;
      font-size: 12px;

      @media (min-width: 414px) {
        font-size: 16px;
      }

      &:hover {
        border: 1px solid #100B2F;
        background: #ffffff;
        color: #100B2F;
      }
    }
  }
}
</style>
