(<template>
  <div class="LanguageSelector">
    <select class="footerLink" @change="onChange" v-if="languages.length > 0">
      <option :value="lang.slug" :selected="$context.language === lang.slug " v-for="lang in languages">
        {{gettext(lang.label)}}
      </option>
    </select>
  </div>
</template>)

<script>
  export default {
    name: 'LanguageSelector',
    data () {
      return {
        languages: []
      }
    },
    methods: {
      onChange (e) {
        let url = window.location.href
        window.location.href = url.replace(new RegExp(`/${this.$context.language}/`), `/${e.target.value}/`)
      }
    },
    mounted () {
      this.languages = [
        {
          slug: 'it',
          label: this.gettext('Italiano')
        },
        {
          slug: 'en',
          label: this.gettext('Inglese')
        },
      ]
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';

  select.footerLink {
    outline: none;
    border: none;
    background: transparent;
    color: $white;
  }
</style>
