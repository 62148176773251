import axios from 'axios'
import * as types from './mutation_types'

export default {
  async loadAiData ({ commit, rootState }, { meta, name, useFixture }) {
    // Get all 'ai' data
    const ai = useFixture
        ? (await import('@fixtures/ai_fixture')).AI
        : (await axios.get( rootState.api['machine-learning-application-sector'])).data
    commit(types.SET_AI_CONTENT, ai)
  },
  async loadQuotes({ commit, rootState }) {
    // Get all 'quotes' data
    const quotes = (await axios.get( rootState.api['machine-learning-carousel'])).data
    commit(types.SET_QUOTES, quotes)
  },
  async loadDynamicTexts({ commit, rootState }) {
    // Get 'dynamicText' data
    const dynamicTexts = (await axios.get( rootState.api['machine-learning-dynamic-text'])).data
    commit(types.SET_DYNAMIC_TEXTS, dynamicTexts)
  },
  async loadAiServices({ commit, rootState }) {
    // Get all 'aiServices' data
    const services = (await axios.get( rootState.api['machine-learning-ai-service'])).data
    commit(types.SET_AI_SERVICES, services)
  },
}
