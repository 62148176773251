import * as types from './mutation_types'

export default {
  [types.SET_AI_CONTENT] (state, data) {
    state.aiContent = data
  },
  [types.SET_QUOTES](state,data){
    state.quotes = data
  },
  [types.SET_DYNAMIC_TEXTS](state,data){
    state.dynamicTexts = data
  },
  [types.SET_AI_SERVICES](state,data){
    state.aiServices = data
  }
}
