import { mapActions } from 'vuex'

/*
 * Common utilities
 *
 * This mixin requires the following:
 * - vue-bootstrapper and the i18n store modle
 * - staticRoot value exposed in the $context
 * - i18n Vuex module
 */

export default {
  computed: {
    getStaticPath () {
      return (path) => `${this.$context.staticRoot}${path}`
    }
  }
}
