/* Import dependencies */
import axios from 'axios'
import * as types from './mutation_types'

export default {
  async getPolicies ({ commit, state, rootState }, { useFixture }) {
    if (!state.privacy || !state.cookie) {
      if (useFixture) {
        const response = await import('@fixtures/policies_fixture')
        commit(types.SET_PRIVACY_POLICY, response.PRIVACY)
        commit(types.SET_COOKIE_POLICY, response.COOKIE)
      } else {
        const { data } = await axios.get(`${rootState.api['policies']}`)
        const privacy = data.find(p => p['policy_type'] === 'privacy')
        const cookie = data.find(p => p['policy_type'] === 'cookie')
        commit(types.SET_PRIVACY_POLICY, privacy)
        commit(types.SET_COOKIE_POLICY, cookie)
      }
    }
  }
}
