import Vue from 'vue'

export const EnterFx = {
  inserted(el, binding) {
    const hiddenClass = 'visibility-hidden'
    el.classList.add(hiddenClass)

    let scrollHandler = () => {
      if (inViewport(el)) {
        el.classList.add(binding.value)
        el.classList.remove(hiddenClass)
        window.removeEventListener('scroll', scrollHandler)
      }
    }


    const inViewport = (el, threshold = 40) => {
      let
        rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / - rect.height) * 100)) < threshold ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < threshold
      )
    };


    window.addEventListener('scroll', scrollHandler)
    scrollHandler()
  }
}

Vue.directive('enter-fx', EnterFx)
