/* Import dependencies */
import * as types from '../mutation_types';
import * as local_types from './mutation_types';

export default {
  async updateRules ({commit},rules) {
    commit(types.UPDATE_RULES, rules)
  },
  async updateData ({commit},data) {
    commit(types.UPDATE_DATA, data)
  },
  async setStatus({ commit }, status ){
    commit(local_types.SET_STATUS, status)
  }
}
