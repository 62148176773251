import * as types from './mutation_types'

export default {
  [types.SET_WHAT_CONTENT] (state, data) {
    state.whatContent = data
  },
  [types.SET_WHO_CONTENT] (state, data) {
    state.whoContent = data
  },
  [types.SET_WHERE_CONTENT] (state, data) {
    state.whereContent = data
  }
}
