import Vue from 'vue'

import * as types from './mutation_types'

export default {
  [types.UPDATE_PAGINATION] (state, { count, current_page, final_page, next, previous }) {
    state.pagination = { count, current_page, final_page, next, previous }
  },
  [types.ADD_PAGE] (state, { page_number, data }) {
    state.pages.push({ page_number, data })
  },
  [types.UPDATE_PAGE] (state, { page_number, data }) {
    let pages = state.pages,
      index

    pages.filter((a, i) => {
      if (a.page_number === page_number) {
        index = i
      }
      return a.page_number === page_number
    })
    pages[index] = { page_number, data }
    Vue.set(state, 'pages', pages)
  },
  [types.SET_DETAIL] (state, data) {
    state.detail = data
  },
  [types.SET_LATEST_WORKS] (state, data) {
    state.latest_works = data
  }
}
