import * as types from './mutation_types'

export default {
  [types.SET_APP_READY] (state) {
    state.appReady = true
  },
  [types.SET_NAVIGATION_STATUS] (state, status) {
    state.navigating = status
  },
  [types.MAP_API] (state, apiObject) {
    state.api = apiObject
  },
  [types.SET_FULLSCREEN_MESSAGE] (state, message) {
    state.fullscreenMessage = message
  },
  [types.SET_IMILLE_LOCATIONS] (state, data) {
    state.locations = data
  },
  [types.SET_IMILLE_POLICIES] (state, data) {
    state.policies = data
  },
  [types.SET_CURRENT_POLICY] (state, slug) {
    state.currentPolicy = state.policies.find(p => p.policy_type === slug)
  },
  [types.SET_SEO_META] (state, meta) {
    state.metaData = meta
  },
  [types.SET_CURRENT_VIEWPORT_WIDTH] (state, w) {
    state.currentViewport = w
  },
  [types.SET_MAIN_MENU] (state, menu) {
    let l = [],
        regex = /-/gi;
    menu.forEach((v)=>{
      l.push({
        label: v.menu_title,
        name: v.name,
        classes: v.name === 'get-in-touch' ? 'button': '',
        path: v.path,
        children: v.children,
        menu_title: v.menu_title
      })
    })
    state.mainMenu = l
  }
}
