import axios from 'axios'
import * as types from './mutation_types'

export default {
  async loadApproachData ({ commit, rootState }, { meta, name, useFixture }) {
    // Get all 'approach' data
    const approach = useFixture
        ? (await import('@fixtures/approach_fixture')).APPROACH
        : (await axios.get( rootState.api['approach'] ) ).data
    commit(types.SET_APPROACH_CONTENT, approach)
  }
}
