import * as types from './mutation_types'

export default {
  [types.SET_PRIVACY_POLICY] (state, data) {
    state.privacy = data
  },
  [types.SET_COOKIE_POLICY] (state, data) {
    state.cookie = data
  },
  [types.SET_PERSONAL_DATA_POLICY] (state, data) {
    state.personalData = data
  }
}
