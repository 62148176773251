var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"main-panel",rawName:"v-main-panel"}],class:['Frankhood', _vm.activeRoute]},[(this.$route.meta.headerImageUrl || this.$route.meta.headerImageUrlMobile )?_c('div',{class:['headerBackground', _vm.aiBgClass ],style:(_vm.styles)}):_vm._e(),_vm._v(" "),_c('page-header',{attrs:{"themeClass":_vm.$route.meta.headerTheme}}),_vm._v(" "),_c('common-icons'),_vm._v(" "),_c('main',[(_vm.appReady || _vm.usePrerender)?_c('router-view'):_vm._e()],1),_vm._v(" "),_c('page-footer'),_vm._v(" "),(!_vm.appReady && !_vm.usePrerender)?_c('div',{class:{'fullscreenLoader sitePreloader': !_vm.pageLoaderDisabled}},[_c('loader')],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"offcanvas",rawName:"v-offcanvas:page-loader",value:({
    lockScroll: false,
    position: 'bottom',
    enterFrom: 'bottom',
    exitTo: 'top',
    duration: 500,
    openAnimation: 'slide-in',
    closeAnimation: 'fade-out',
    ease: true,
    beforeEnter:_vm.beforeEnter,
    afterEnter: _vm.hideBody,
    beforeLeave: _vm.showBody,
    afterLeave: _vm.afterLeave
  }),expression:"{\n    lockScroll: false,\n    position: 'bottom',\n    enterFrom: 'bottom',\n    exitTo: 'top',\n    duration: 500,\n    openAnimation: 'slide-in',\n    closeAnimation: 'fade-out',\n    ease: true,\n    beforeEnter:beforeEnter,\n    afterEnter: hideBody,\n    beforeLeave: showBody,\n    afterLeave: afterLeave\n  }",arg:"page-loader"}],class:{'fullscreenLoader pageLoader': !_vm.pageLoaderDisabled && !_vm.usePrerender}},[_vm._v(" \n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }