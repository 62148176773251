import Vue from 'vue'
import VueBootstrapper from 'vue-bootstrapper'
import VueScrollTo from 'vue-scrollto'
import axios from 'axios'
import moment from 'moment'
import VueObserveVisibility from 'vue-observe-visibility'
import VueTextareaAutosize from 'vue-textarea-autosize'
import { VueMasonryPlugin } from 'vue-masonry'
import VueViewportManager from 'vue-viewport-manager'
import VueAsyncComputed from 'vue-async-computed'
import VueRx from 'vue-rx'
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueTypedJs from 'vue-typed-js'
import VueRellax from 'vue-rellax'


import store from '@store'
import VueOffcanvas from '@plugins/vue_offcanvas'
import VueDjangoGettext from '@plugins/vue_django_gettext'
import VueFiletype from '@plugins/vue_filetype'

import initRouter from './spa/router'
import Frankhood from './spa/frankhood'

import mockRoutes from '@fixtures/mock_routes'

import VueCommonMixin from '@mixins/common_mixin'
import VueGettextTranslate from '@mixins/vue_django_gettext'

Vue.use(VueViewportManager, {store})
Vue.use(VueOffcanvas, {store})
Vue.use(VueDjangoGettext, {store})
Vue.use(VueBootstrapper)
Vue.use(VueTextareaAutosize)
Vue.use(VueObserveVisibility)
Vue.use(VueFiletype)
Vue.use(VueAsyncComputed)
Vue.use(VueMasonryPlugin)
Vue.use(VueScrollTo)
Vue.use(VueRx)
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueTypedJs)
Vue.use(VueRellax)

import '@directives/fx-directive'

Vue.mixin(VueGettextTranslate)
Vue.mixin(VueCommonMixin)

function vueOptions (router, locale) {
  return {
    el: '#app-root',
    store,
    data(){
      return {
        pageLoaderLeaved:true
      }
    },
    created () {
      moment.locale(locale)
    },
    mounted () {
      var elem = document.getElementById('gpsTemp');
      if (elem) {
        elem.parentNode.removeChild(elem);
      }
      this.$store.dispatch('mapApi', this.$context.api).then(
        this.$store.dispatch('retrieveMainMenu', this.$context.menuApi)
      )
      if (window.history) {
        history.scrollRestoration = 'manual'
      }
    },
    render (h) {
      let FrankhoodProxy = Vue.extend(Frankhood)
      FrankhoodProxy.options.router = router
      return h(FrankhoodProxy)
    }
  }
}

export default (function () {
  let lang = document.getElementById('app-root')
      .getAttribute('data-language'),
    urlModelsApi = document.getElementById('app-root')
      .getAttribute('data-router-api')


  axios.get(urlModelsApi)
    .then(response => {
      const routes = response.data,
        locale = lang,
        router = initRouter(locale, routes)

      return new Vue(vueOptions(router, locale))
    })
    .catch(error => {
      console.warn('[URL MODEL API] ERRORE NEL RECUPERO DELLE API CONTINUO SOLO PER RAGIONI DI SVILUPPO')
      console.warn(error)

      const routes = mockRoutes,
        locale = 'it',
        router = initRouter(locale, routes)

      return new Vue(vueOptions(router, locale))
    })
})()
