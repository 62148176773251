import { of } from "rxjs"

export default {
  flatData: (state) => {
    return state.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data)
    }, [])
  },
  authorAndDate: (state) =>{
  	if(!!state.detail){
  		let detail = state.detail
  		/* <time datetime="2018-12-01">01/12/2018</time>, Giovanni Battista Brescia */
  		return `<time datetime="${detail.publication_date}">${detail.publication_date}</time>${!!detail.author_name ? `, ${detail.author_name}` : ""}`
  	}else{
  		return ""
  	}
  }
}
