import * as types from '../mutation_types'
import * as local_types from './mutation_types'


export default {
  [types.UPDATE_RULES] (state, rules) {
    state.rules = rules
  },
  [types.UPDATE_DATA] (state, data) {
    state.form = data
  },
  [local_types.SET_STATUS] (state, status){
  	state.status = status
  }
}
