import * as types from './mutation_types'

export default {
  [types.LOAD_DATA] (state, meta) {
    state.mainTitle = !!meta.mainTitle ? meta.mainTitle : ""
    state.mainSubtitles = !!meta.mainSubtitles ? meta.mainSubtitles : [ "" ]
    state.mainDescription = !!meta.mainDescription ? meta.mainDescription : ""
    state.weWorkWithTitle = !!meta.weWorkWithTitle ? meta.weWorkWithTitle : ""
    state.weWorkWithDescription = !!meta.weWorkWithDescription ? meta.weWorkWithDescription : ""
    state.countersTitle = !!meta.countersTitle ? meta.countersTitle : ""
    state.countersDescription = !!meta.countersDescription ? meta.countersDescription : ""

    let counters = !!meta.counters ? [...meta.counters] : []
    counters.forEach( a=> a.selected = false )
    state.counters = counters

  }
}
