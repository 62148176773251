import * as types from './mutation_types'

export default {
  [types.UPDATE_PAGINATION] (state, {count, current_page, final_page, next, previous}) {
    state.pagination = {count, current_page, final_page, next, previous}
  },
  [types.ADD_PAGE] (state, {page_number, data}) {
    state.pages.push({page_number, data})
  },
  [types.SET_DETAIL] (state, data) {
    state.detail = data
  }
}
