/* Mutations, actions and getters */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    pagination: {
      count: null,
      current_page: null,
      final_page: null,
      next: null,
      previous: null
    },
    pages: [],
  },
  actions,
  mutations,
  getters
}
