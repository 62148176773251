import axios from 'axios'
import * as types from './mutation_types'

export default {
  async loadServicesData ({ commit, rootState }, { meta, name, useFixture }) {
    // Get all 'services' data
    const services = useFixture
        ? (await import('@fixtures/services_fixture')).SERVICES
        : (await axios.get( rootState.api['services'] ) ).data
    commit(types.SET_SERVICES_CONTENT, services)
  }
}
