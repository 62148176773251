/* Import dependencies */
import * as types from './mutation_types'
import axios from 'axios'
export default {
  getCatalog({commit, state},url) {
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(response => {
          commit(types.SET_I18N_CATALOG, response.data);
          resolve()
        })
        .catch(err => {
          console.warn('i18n catalog API unavailable');
          reject()
        })
    })
  }
}
