(<template>
  <footer class="PageFooter">
    <div class="pageFooterWrapper section-padding-top section-half-padding-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-8 leftColumn">
            <h3 class="componentTitle">
              <span class="opacity 050">{{gettext('Do you have a project?')}}</span><br/>
              {{gettext("Let's talk.")}}
            </h3>
            <div class="linkWrapper">
              <a href="#" class="contactUsLink" @click.prevent="onContactUsClick">
                > {{gettext('Contact Us')}}
              </a>
            </div>
            <div class="django" v-html="loveDjango">
            </div>
          </div>
          <div class="col-md-2 offset-md-2">
            <nav role="navigation" class="footerNav">
              <ul class="bottomNavList">
                <li
                  class="bottomLink"
                  v-for="entry in mainMenu"
                  :key="`flink-${entry.name}`"
                >
                  <router-link class="link" :to="entry">
                    {{ entry.label }}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-md-12">
            <div class="flex wrapper">
              <div class="flex infoWrapper">
                <a href="#" class="brand">
                  <svg
                    width="100%"
                    viewBox="0 0 50 15"
                  >
                    <use href="#icon-brand-fh-small" fill="currentColor" />
                  </svg>
                </a>
                <div class="flexItem">
                  <p class="credits">
                    ©{{ currentYear }}
                    {{gettext('Frankhood Business Solutions s.r.l. _ via Domenico Cotugno 49/a, 70124 Bari (Italy) Reg. Imprese di Bari _ REA ba-544839 _ P.IVA: 07265300728 _ Cap. Sociale: 10.000 €')}}
                  </p>
                  <div class="links flex justify-start">
                    <div class="flexItem">
                      <router-link
                        class="footerLink"
                        :to="{name: 'policies', params: {slug: 'privacy'}}"
                      >
                        Privacy Policy
                      </router-link>
                    </div>
                    <div class="separator">
                      _
                    </div>
                    <div class="flexItem">
                      <router-link
                        class="footerLink"
                        :to="{name: 'policies', params: {slug: 'cookie'}}"
                      >
                        Cookie Policy
                      </router-link>
                    </div>
                    <div class="separator" v-if="activateLanguageSelector">
                      _
                    </div>
                    <div class="flexItem" v-if="activateLanguageSelector">
                      <language-selector></language-selector>
                    </div>
                  </div>
                </div>
              </div>
              <div class="socialBtns">
                <a href="https://www.facebook.com/frankhood.dev"
                   rel="noopener noreferrer"
                   target="_blank"
                   class="socialBtn"
                >
                  <svg
                    width="100%"
                    viewBox="0 0 30 30"
                  >
                    <use href="#icon-social-facebook" fill="currentColor" />
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/frankhood-business-solutions-srl"
                   rel="noopener noreferrer"
                   target="_blank"
                   class="socialBtn"
                >
                  <svg
                    width="100%"
                    viewBox="0 0 30 30"
                  >
                    <use href="#icon-social-linkedin" fill="currentColor" />
                  </svg>
                </a>
                <a href="https://www.instagram.com/frankhood.dev"
                   rel="noopener noreferrer"
                   target="_blank"
                   class="socialBtn"
                >
                  <svg
                    width="100%"
                    viewBox="0 0 30 30"
                  >
                    <use href="#icon-social-instagram" fill="currentColor" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>)

<script>

  import moment from 'moment'
  import { mapState, mapActions, mapGetters } from 'vuex'

  import LanguageSelector from './language_selector.vue'


  export default {
    name: 'PageFooter',
    components: {
      LanguageSelector
    },
    computed: {
      ...mapState(['activateLanguageSelector', 'mainMenu']),
      currentYear () {
        return moment().format('YYYY')
      },
      loveDjango(){
        return this.gettext('We <i class="djangoHeart"></i> Django')
      }
    },
    methods: {
      onContactUsClick(){
        this.$router.push({ name: 'get-in-touch' })
      },
      onChange(e){
        //console.log(e);

      }
    },
    mounted(){
      //console.table(this.$context)
    }
  };
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  @import '~$scss/utility';

  .pageFooterWrapper {
    background: $dark-blue;
  }

  .leftColumn {
    @media(min-width: $bootstrap-md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .componentTitle {
    margin: 0 0 20px;
    color: $white;
    font-weight: $light;
    @include typo-h2;

    @media (min-width: $bootstrap-md) {
      margin-bottom: 0;
    }

    .opacity {
      color: rgba($white, 0.5);
    }
  }

  .footerNav {
    @media (max-width: $bootstrap-md - 1px) {
      margin-top: 15px;
    }
  }

  .contactUsLink {
    color: $yellow;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.5s ease-out;

    &:hover {
      border-bottom-color: $yellow;
    }
  }

  .django {
    color: $white;
    margin-top: 20px;

    @media (min-width: $bootstrap-md) {
      margin-top: 0;
    }

    .djangoHeart, /deep/ .djangoHeart {
      display: inline-block;
      width: 1em;
      height: 1em;
      background: transparent url('~@images/django-heart.png') no-repeat bottom center;
      background-size: cover;
    }
  }

  .bottomNavList {
    margin: 0;
    padding: 0;
  }

  .bottomLink {
    display: block;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;

    .link {
      color: $white;

      &:hover {
        border-bottom-color: $white;
      }
    }
  }

  .wrapper {
    padding-top: 50px;
  }

  hr {
    @media (min-width: $bootstrap-md) {
      padding-bottom: 85px;
    }
    border: none;
    border-bottom: 1px solid rgba($white, .25);
  }

  .brand {
    --brand-color-primary: white;
    display: block;
    width: 72.28px;
    margin-right: 37px;
    margin-bottom: 10px;
    @media (min-width: $bootstrap-md) {
      margin-top: 5px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    &.justify-start{
      justify-content: flex-start;
    }
    @media (max-width: $bootstrap-md - 1px) {
      flex-direction: column;
    }

    p {
      margin: 0;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .links{
    .flexItem:last-child{
      /*margin-left: -8px;*/
    }
    @media (max-width: $bootstrap-md - 1px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .credits {
    color: rgba($white, 0.5);
  }

  .separator{
    margin-left: 10px;
    margin-right: 10px;
  }

  .links,
  .footerLink {
    font-size: 12px;
    line-height: 20px;
    color: $white;
    text-decoration: none;
  }

  select.footerLink{
    outline: none;
    border: none;
    background: transparent;
  }

  .socialBtns {
    display: flex;
  }

  .infoWrapper .flexItem {
    max-width: 650px;
    @media (max-width: $bootstrap-md - 1px) {
      margin-bottom: 10px;
    }
  }

  .socialBtn {
    display: block;
    width: 35px;
    height: 35px;
    margin-right: 15px;
    padding: 0.5em;
    background-color: rgba($white, 0.1);
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-color: rgba($white, 0.2);
    }

    &:last-child {
      margin-right: 0;
    }
  }
</style>
