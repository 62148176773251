const vueFiletype = {
  install (Vue, options) {

    Vue.mixin({
      methods: {
        identifyMedia(path){
          let filename = path.substring(path.lastIndexOf('/')+1);
          if(filename.match(/([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.gif|.png)$/i)){
            return 'image'
          }else if(filename.match(/([a-zA-Z0-9\s_\\.\-\(\):])+(.mpeg|.mpg|.mp4|.avi|.mov)$/i)){
            return 'video'
          }
        },
        getVideoType(path){
          let filename = path.substring(path.lastIndexOf('/')+1);
          if(filename.match(/([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.gif|.png)$/i)){
            return 'image'
          }else if(filename.match(/([a-zA-Z0-9\s_\\.\-\(\):])+(.mpeg|.mpg|.mp4|.avi|.mov)$/i)){
            return 'video'
          }
        }
      }

    })
  }
}

export default vueFiletype
