export default {
  flatData: (state) => {
    return state.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data)
    }, [])
  },

  detailTitles: (state) =>{
  	return !!state.detail && state.detail.title ? [state.detail.title] : []
  },

  info: (state)=>{
  	let ar = [];
  	if(!!state.detail){

      if(state.detail.publication_date){
        ar.push({ label: gettext('Anno'), value: state.detail.publication_date.substr(0, state.detail.publication_date.indexOf('-'))  })
      }
  	}

  	return ar
  }
}
