export default [
  {
    name: 'home',
    path: '/',
    meta: {
      headerTheme: 'positionAbsolute'
    }
  },
  /*
  {
    name: 'about',
    path: '/about'
  },
  {
    name: 'vision',
    path: '/vision'
  },
  {
    name: 'works',
    path: '/works'
  },
  {
    name: 'work-detail',
    path: '/works/:slug'
  },
  {
    name: 'experiments',
    path: '/experiments'
  },
  {
    name: 'experiment-detail',
    path: '/experiments/:slug'
  },
  {
    name: 'news',
    path: '/news'
  },
  {
    name: 'news-detail',
    path: '/news/:slug'
  },
  {
    name: 'careers',
    path: '/careers'
  },
  {
    name: 'get-in-touch',
    path: '/get-in-touch'
  },
  {
    name: 'policies',
    path: '/policies/:slug'
  }
  */
]
