
/* Router dependencies */
import imagesLoaded from 'imagesloaded'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '@store'

Vue.use(VueRouter)
Vue.use(VueMeta)

import { dummyPromise } from '@helpers/promise_helpers'

// Hook functions that need to be reused through multiple routes
const loadAboutData = async (to, from, next) => {
  try {
    await store.dispatch(
      'about/loadAboutData',
      { name: to.name, meta: to.meta, useFixture: false }
    )
    next()
  } catch (e) {
    next(false)
  }
}

const matchServicesRoutes = (to, from) => {
  return (from.name === 'services' || from.name === 'services.detail') &&
    (to.name === 'services' || to.name === 'services.detail')
}

const handleError = (err, next) => {
  //console.log(err)
  switch (err.response.status) {
    case 404:
    case 401:
    case 403:
      next({ name: 'not_found' })
      break

    case 502:
    case 500:
      next({ name: 'server_error' })
      break

    default:
      next({ name: 'error' })
      break

  }
}

const paginatedApiPreFetch = (module_name, page_size) => async (to, from, next) => {
  try {
    if (store.state[module_name].pages.length === 0) {
      await store.dispatch(`${module_name}/initPagination`, { page_size })
      next()
    } else {
      next()
    }
  } catch (e) {
    console.log(e)
  }
}
const baseRoutes = [
  {
    name: 'home',
    component: () => import('./views/home'),
    path: '/',
    beforeEnter: (to, from, next) => {
      Promise.all([
        store.dispatch(`works/initOrGetdata`, {}),
        store.dispatch(`works/getLatestWorks`, {}),
        store.dispatch(`brands/initOrGetdata`, {}),
        store.dispatch(`home/getData`, to),
        store.dispatch(
          'services/loadServicesData',
          { name: to.name, meta: to.meta, useFixture: false }
        )
      ]).then(next)
    }
  },

  {
    name: 'about',
    component: () => import('./views/about'),
    path: '/about',
    redirect: { name: 'about.vision' },
    children: [
      {
        name: 'about.what',
        component: () => import('./views/about/about_what'),
        path: '/about/what',
        beforeEnter: loadAboutData
      },
      {
        name: 'about.who',
        component: () => import('./views/about/about_who'),
        path: '/about/who',
        beforeEnter: loadAboutData
      },
      {
        name: 'about.where',
        component: () => import('./views/about/about_where'),
        path: '/about/where',
        beforeEnter: loadAboutData
      },
      {
        name: 'about.vision',
        component: () => import('./views/about/about_vision'),
        path: '/about/vision',
        beforeEnter: loadAboutData
      },
      {
        name: 'about.more',
        component: () => import('./views/about/about_more'),
        path: '/about/more',
        beforeEnter: loadAboutData
      }
    ]
  },
  {
    name: 'services',
    component: () => import('./views/services'),
    path: '/services',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch(
          'services/loadServicesData',
          { name: to.name, meta: to.meta, useFixture: false }
        )

        next()
      } catch (e) {
        next(false)
      }
    }
  },
  {
    name: 'services.detail',
    component: () => import('./views/services'),
    path: '/services/:slug',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch(
          'services/loadServicesData',
          { name: to.name, meta: to.meta, useFixture: false }
        )
        let contentId = store.state.services.servicesContent.results.find(item => item.slug === to.params.slug).id
        await store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: contentId })
        next()
      } catch (e) {
        handleError(e, next)
      }
    }
  },
  {
    name: 'approach',
    component: () => import('./views/approach'),
    path: '/approach',
    meta: {
      headerImageUrl: '/static/img/why_we_do_it_bg.png',
      headerTheme: 'positionAbsolute whiteTheme',
      headerImageColor: '#5738FF'
    },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch(
          'approach/loadApproachData',
          { name: to.name, meta: to.meta, useFixture: false }
        )

        next()
      } catch (e) {
        next(false)
      }
    }
  },
  {
    name: 'ai-machine-learning',
    component: () => import('./views/ai-machine-learning'),
    path: '/ai-machine-learning',
    meta: {
      headerImageUrl: '/static/img/ai-background-hero.png',
      headerImageUrlMobile: '/static/img/ai-background-hero-mobile.png',
      headerTheme: 'ai-background',
    },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch(
          'ai/loadAiData',
          { name: to.name, meta: to.meta, useFixture: false }
        )
        await store.dispatch(
          'ai/loadQuotes' 
        )
        await store.dispatch(
          'ai/loadDynamicTexts' 
        )
        await store.dispatch(
          'ai/loadAiServices' 
        )
        store.dispatch('news/initPagination', 999)
        store.dispatch('form/setStatus', '').then(() => {
          store.dispatch('form/updateData', {
            first_name: '',
            last_name: '',
            email: '',
            contact_reason: 'spontaneous',
            message: '',
            accepts_privacy: ''
          })
        }).then(next)
      } catch (e) {
        next(false)
      }
    }
  },
  {
    name: 'works',
    component: () => import('./views/works'),
    path: '/works',
    beforeEnter: paginatedApiPreFetch('works', 12)
  },
  {
    name: 'work-detail',
    component: () => import('./views/work_detail'),
    path: '/works/:slug',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('works/getDetail', to.params.slug)
        let contentId = store.state.works.detail.id
        await store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: contentId })
        next()
      } catch (e) {
        handleError(e, next)
      }
    }
  },
  {
    name: 'experiments',
    component: () => import('./views/experiments'),
    path: '/experiments',
    beforeEnter: paginatedApiPreFetch('experiments', 12)
  },

  {
    name: 'experiment-detail',
    component: () => import('./views/experiment_detail'),
    path: '/experiments/:slug',
    beforeEnter: (to, from, next) => {
      store.dispatch('experiments/getDetail', to.params.slug)
        .then(() => {
          const contentId = store.state.experiments.detail.id
          return store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: contentId })
        })
        .then(next)
        .catch((err) => {
          handleError(err, next)
        })
    }
  },

  {
    name: 'news',
    component: () => import('./views/news'),
    path: '/news',
    meta: {
      headerImageUrl: '/static/img/news_bg.jpg',
      headerTheme: 'positionAbsolute whiteTheme',
      headerImageColor: '#885EF2'
    },
    beforeEnter: paginatedApiPreFetch('news', 12)
  },
  {
    name: 'news-detail',
    component: () => import('./views/news_detail'),
    path: '/news/:slug',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('news/getDetail', to.params.slug)
        let contentId = store.state.news.detail.id
        await store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: contentId })
        next()
      } catch (e) {
        handleError(e, next)
      }
    }
  },
  {
    name: 'careers',
    component: () => import('./views/careers'),
    path: '/careers',
    beforeEnter: paginatedApiPreFetch('careers', 12)
  },
  {
    name: 'get-in-touch',
    component: () => import('./views/get_in_touch'),
    path: '/get-in-touch',
    beforeEnter: (to, from, next) => {
      store.dispatch('form/setStatus', '').then(() => {
        store.dispatch('form/updateData', {
          first_name: '',
          last_name: '',
          email: '',
          contact_reason: 'spontaneous',
          message: '',
          accepts_privacy: ''
        })
      }).then(next)
    },
    meta: {
      headerImageUrl: '/static/img/get_in_touch_header_bg.jpg',
      headerTheme: 'positionAbsolute whiteTheme',
      headerImageColor: '#03BEBE'
    },
  },
  {
    name: 'policies',
    component: () => import('./views/policy'),
    path: '/policies/:slug',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('policies/getPolicies', { useFixture: false })
        let contentId = store.state.policies[to.params.slug]?.id;
        if (contentId) {
          await store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: contentId })
        }
        next()
      } catch (e) {
        // TODO show toast with error
        handleError(e, next)
      }
    }
  }
]

const initRouter = (base, routes) => {
  const router = new VueRouter({
    base,
    mode: 'history',
    routes: [],
    scrollBehavior(to, from, savedPosition) {
      if (matchServicesRoutes(to, from)) {
        return savedPosition
      }
      else {
        return { x: 0, y: 0 }
      }
    }
  })

  const mergeRoutes = function (routes, baseRoutes) {
    //console.log("ROUTES >>>", routes, "BASE_ROUTES >>>", baseRoutes)
    let output = []

    routes.forEach(r => {
      //console.log(r.name,baseRoutes)
      let corr = baseRoutes.find(x => x.name === r.name)

      if (!!corr) {
        if (!!r.children && r.children.length > 0) {
          let children = mergeRoutes(r.children, corr.children)
          output.push({
            ...corr,
            path: r.path,
            meta: Object.assign({}, corr.meta, r.meta),
            children
          })
        } else {
          output.push({
            ...corr,
            path: r.path,
            meta: Object.assign({}, corr.meta, r.meta)
          })
        }
      }
    })

    return output
  }

  let merged = mergeRoutes(routes, baseRoutes)

  //console.log("MERGED>>>>", merged)

  router.addRoutes([
    {
      path: '/not-found/',
      name: 'not_found',
      component: () => import('./views/server_error'/* not_found */),
    },
    {
      path: '/server-error/',
      name: 'server_error',
      component: () => import('./views/server_error'),
    },
    {
      path: '/error/',
      name: 'error',
      component: () => import('./views/error'),
    },
    {
      path: '*',
      redirect: { name: /* 'home' */ 'not_found' }
    }])
  router.addRoutes(merged)

  router.beforeEach((to, from, next) => {
    store.dispatch('setNavigationStatus', true)
      .then(() => {
        //debugger
        if (store.state.appReady) {
          let header = router.app.$children.find(el => el.$options.name === 'PageHeader')
          if (header.menuOpen) {
            return header.toggleMenu()
          } else {
            return dummyPromise()
          }
        } else {
          return store.dispatch('i18n/getCatalog')
        }
      })
      .then(() => {
        if (!!to.meta.id) {
          return store.dispatch('retrievePageMeta', { cType: to.meta.cType, id: to.meta.id })
        } else {
          return dummyPromise()
        }
      })
      .then(() => {
        if (store.state.appReady) {
          if (matchServicesRoutes(to, from)) {
            next()
          }
          else if (store.getters['vueOffcanvas/offcanvasOpeningState']('fullscreen-message')) {
            router.app.$openOffcanvas('page-loader')
              .then(next)
          } else {
            router.app.$openOffcanvas('page-loader')
              .then(() => store.dispatch('initializeApplication'))
              .then(next)
          }
        } else {
          next()
        }
      })
  })

  router.afterEach((to, from) => {
    if (store.state.appReady) {
      imagesLoaded(router.app.$el, () => {
        router.app.$closeOffcanvas('page-loader')
          .then(() => store.dispatch('setNavigationStatus', false))
      })
    } else {
      setTimeout(() => {
        imagesLoaded(router.app.$el, () => {
          router.app.$openOffcanvas('page-loader')
            .then(() => store.dispatch('initializeApplication'))
            .then(() => router.app.$closeOffcanvas('page-loader'))
            .then(() => store.dispatch('setNavigationStatus', false))
        })
      }, 2000)
    }
    setTimeout(function () {
      if (window.gtag) {
        window.gtag('config', 'UA-33948311-1', { 'anonymize_ip': true, 'page_path': window.location.pathname });
        window.ga('set', 'page', window.location.pathname);
        window.ga('send', 'pageview');
      }

    }, 3000);
  })

  return router
}

export default initRouter
